.ec-divider {
  margin-top: 16px;
  margin-bottom: 16px;

  &--lg {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &--primary {
    border-bottom: 1px solid var(--base-ocher-40);
  }

  &--grey {
    border-bottom: 1px solid var(--medium-grey);
  }

  &--lgrey {
    border-bottom: 1px solid var(--white-hover);
  }

  &--transparent {
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
}
