@import './shared/variables';
@import './shared/breakpoints';

ec-select {
  &.ng-touched.ng-invalid {
    label {
      color: var(--base-red-60);
    }

    .ng-select-container {
      border-color: var(--base-red-60) !important;
      color: var(--base-blue-80);
    }
  }

  &[required] {
    label {
      &::after {
        content: '*';
        color: var(--base-red-60);
        margin-left: 3px;
      }
    }
  }
}

.ng-select {
  &.ng-select-focused:not(.ng-select-opened) {
    .ng-select-container {
      border-color: var(--base-blue-80) !important;
      box-shadow: none;
    }
  }

  .ng-arrow-wrapper {
    margin-right: 11px !important;
    display: flex;
    width: auto !important;
  }

  .ng-arrow-wrapper::after {
    content: '';
    background-image: url('/assets/icons/arrow-down.svg');
    height: 24px;
    width: 24px;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &.ng-select-opened > .ng-select-container .ng-arrow-wrapper::after {
    transform: scaleY(-1);
  }

  .ng-arrow {
    display: none !important;
  }

  &.ng-select-disabled > .ng-select-container {
    background: var(--base-purple-5);
    color: var(--base-blue-80);
    border: 1px solid var(--base-purple-30);
  }

  &.ng-select-single .ng-select-container {
    .ng-value-container .ng-input {
      top: unset !important;

      input {
        position: initial;
      }
    }
  }

  .ng-select-container {
    min-height: 40px;
    border-radius: 8px;
    border-color: var(--base-purple-30);
  }

  &.invalid,
  &.ng-touched.ng-invalid {
    .ng-select-container {
      border-color: var(--red) !important;
      color: var(--base-blue-80);
    }
  }
}

.ng-dropdown-panel .scroll-host {
  min-width: 100px !important;
  overflow: visible;
}

.ng-dropdown-panel {
  z-index: 999 !important;
  overflow: hidden;
  border-radius: 8px !important;
  min-width: 152px !important;
  box-shadow: 0 15px 29px rgba(0, 0, 0, 0.2);
  border: 1px solid var(--base-grey-20) !important;
}

.ng-dropdown-footer {
  padding: 8px 10px 8px 16px !important;
  background-color: var(--base-purple-5) !important;
  cursor: pointer !important;

  font-weight: 600;
  line-height: 22px;

  &:hover {
    color: var(--green) !important;
    background-color: var(--additional-green-10) !important;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  max-height: 160px;
  display: flex !important;
  align-items: center !important;
  font-weight: 500;

  &.ng-option-selected {
    .ng-select-custom-option {
      font-weight: 600;
    }

    background: var(--base-purple-5);
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: var(--base-purple-70) !important;
  background-color: var(--base-purple-5);
}

.ng-select.ng-select-opened.ng-select-top > .ng-select-container {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option {
  padding-left: 1rem;
  position: relative;
  display: flex;
  align-items: center;
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:first-child {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:not(:last-child) {
  border-bottom: 1px solid var(--medium-grey);
}

.ng-dropdown-panel.ng-select-bottom {
  box-shadow: 0 7px 17px -1px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  margin-top: 8px;
}

.ng-dropdown-panel.ng-select-top {
  box-shadow: 0 7px 17px -1px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  margin-bottom: 8px;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  margin-bottom: 0 !important;
}

.ng-select .ng-select-container {
  color: var(--base-blue-80);
}

.ng-select .ng-select-container .ng-value-container {
  padding-left: 1rem !important;
  font-size: 14px;
  font-weight: 500;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  top: auto !important;
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--base-purple-30);
}

.ng-select-custom-label,
.ng-placeholder {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--base-blue-80);
}

.ng-select-custom-option,
.ng-option {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--base-blue-80);
}

.ng-dropdown-panel.type-select,
.ng-dropdown-panel.priority-select {
  margin-top: -35px !important;
}

@include lt-sm {
  .ng-select .ng-select-container .ng-value-container .ng-input > input {
    padding: 0;
  }
}

.ng-dropdown-panel {
  .ng-dropdown-panel-items {
    .ng-option {
      &:hover,
      &.ng-option-marked {
        background-color: var(--additional-ocher-5);
      }

      &.ng-option-selected {
        .ng-select-custom-option {
          font-weight: 600 !important;
        }
        font-weight: 600 !important;
        background: var(--light-blue) !important;
      }
    }
  }
}
