@import "breakpoints";

.ms-2\.5 {
  margin-left: 0.75rem;
}

.mx-2\.5 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.mb-2\.5 {
  margin-bottom: 0.75rem;
}

.mt-2\.5 {
  margin-top: 0.75rem;
}

.px {
  &-2\.5 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  &-xl-0 {
    @include gt-lg {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  &-xl-2\.5 {
    @include gt-lg {
      padding-left: 0.75rem !important;
      padding-right: 0.75rem !important;
    }
  }
}
