a[ec-icon-wrapper] {
  cursor: pointer;
}

a[ec-link] {
  &.disabled {
    pointer-events: none !important;

    > span {
      text-decoration: none !important;
    }
  }
}
