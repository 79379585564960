@import 'shared/breakpoints';

ec-card-table-header,
ec-card-table-column {
  &:first-child {
    //flex: 0 1 150px;
  }
}

.card-table {
  font-size: 16px;

  &-wrapper {
    display: flex;
    flex-direction: column;

    .meta-info {
      margin-bottom: 20px;

      @include lt-xl {
        margin-bottom: 12px;
      }
    }

    .show-on-touch,
    .show-on-touch {
      display: none;

      @include lt-xl {
        display: block !important;
      }
    }

    .hide-on-touch,
    .hide-on-touch {
      display: block !important;

      @include lt-xl {
        display: none !important;
      }
    }
  }

  &-header {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin: 4px 0;
    //padding-left: 15px;
  }

  &-body {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  @include lt-xl {
    &-header {
      display: none !important;
    }
  }

  &__no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    text-align: center;
  }
}

ec-card-table {
  &.with-padding {
    &-row,
    .touch-sorting,
    .meta-info {
      @include lt-xl {
        padding: 0 12px;
      }
    }
    a[ec-card-table-row] .wrapper-column {
      @include lt-xl {
        margin: 0 12px;
      }
    }
  }

  &-row,
  a[ec-card-table-row]:nth-child(1) {
    margin-top: 0;
  }

  &-row,
  a[ec-card-table-row] {
    transition: height 0.2s ease-in;
    margin: 8px 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: var(--base-white);
    border-radius: 8px;

    border: 1px solid var(--base-grey-20);
    box-shadow: 0 6px 6px rgba(61, 61, 61, 0.04);

    @include lt-xl {
      margin: 4px 0;
    }

    &.scalable {
      transition: transform 0.2s ease;

      &:hover {
        transform: scaleX(1.01);
      }
    }

    .wrapper {
      &-column,
      &-panel {
        background-color: var(--base-white);
        padding: 15px 0;

        @include lt-xl {
          padding: 0 0 15px;
        }
      }

      &-panel {
        padding: 0;
      }

      &-column {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        height: 80px;
        min-height: 80px;
        z-index: 1;
        position: relative;
        transition: height 0.2s ease-in;

        ngb-rating {
          font-size: 40px;
          height: 40px;
          width: 40px;
          align-items: center;
          color: rgb(215, 214, 218);

          &.Featured {
            color: rgb(249, 185, 42);
          }
        }

        &__arrow {
          display: none;
          position: absolute;
          right: 0;
          top: 0;

          @include lt-xl {
            display: block;
            min-width: 24px;
            text-align: center;
          }
        }

        @include lt-xl {
          flex-direction: column;
          height: 100%;
          padding-bottom: 0;
        }
      }
    }
  }

  &-header,
  &-column {
    flex: 1 1 0;
    font-size: 1rem;
    font-weight: 600;
    padding: 0 8px;

    &:first-child {
      padding-left: 15px;
    }

    &:last-child {
      padding-right: 15px;
    }

    //outline: 1px dashed red;

    .column-icon {
      svg {
        width: 24px;
      }
    }
  }

  &-header {
    &.alignment {
      &-center {
        text-align: center;
      }

      &-end {
        text-align: right;
      }

      &-start {
        text-align: left;
      }
    }

    span {
      &.asc {
        .asc {
          fill: var(--base-black);
        }
      }

      &.desc {
        .desc {
          fill: var(--base-black);
        }
      }
    }
  }

  &-header {
    .sortable {
      //margin-right: -15px;
    }

    &:last-child {
      .sortable {
        //margin-right: 0;
      }
    }

    padding: 8px 8px;
    color: var(--base-purple-70);

    &:first-child {
      padding-left: 15px;
    }

    &:last-child {
      padding-right: 15px;
    }
  }

  &-column {
    margin: auto 0;

    @include gt-lg {
      display: block !important;
    }

    @include lt-xl {
      flex-basis: auto !important;
      max-width: 100% !important;
    }

    &.isSubheader {
      display: none !important;

      @include lt-xl {
        display: block !important;
      }
    }

    .column {
      &-content {
        .th {
          display: none;
          color: var(--base-purple-70);
          flex-basis: 35%;
          min-width: 35%;
          font-weight: 600;

          @include lt-xl {
            display: flex;
            align-items: flex-start;
          }

          @include lt-sm {
            min-width: 58%;
          }
        }

        @include lt-xl {
          display: flex !important;
          width: 100%;
        }
      }
    }

    @include lt-xl {
      padding-bottom: 0.5rem;

      &:first-child {
        padding-bottom: 12px;

        padding-top: 1rem;

        .column {
          border-bottom: 1px solid var(--base-grey-20);
          padding-bottom: 1rem;
        }
      }
    }
  }
}

ec-card-table.new-style {
  &.size--lg>.card-table-wrapper>.card-table-header>ec-card-table-header {
    min-height: 64px;
  }

  [ec-card-table-row] {
    &.size--lg>.wrapper-column {
      min-height: 80px !important;
    }

    &.size--md>.wrapper-column {
      min-height: 48px !important;
    }
  }

  &.size--md {
    .wrapper-column {
      min-height: 48px !important;
    }
  }

  [ec-card-table-row] {
    border-left: none;
    border-right: none;
    border-top: unset;
    border-radius: unset;
    box-shadow: unset;
    margin: 0;
    color: var(--base-blue-80);
    min-height: 48px;
    height: unset;
    background-color: var(--base-white);

    @include lt-xl {
      &:first-child {
        border-top: 1px solid var(--base-purple-20);
      }

      &:last-child {
        border-bottom: none;
      }
    }

    .wrapper-column {
      min-height: 48px;
      height: auto;
      padding: 16px 0;
      background-color: inherit;
      align-items: center;

      @include lt-xl {
        padding: 0 0 8px;
        align-items: normal;
      }
    }

    .column {
      border: none !important;
      padding-bottom: 0;
    }
  }

  ec-card-table-column {
    display: block !important;

    span.td {
      font-weight: 500;
      font-size: 14px !important;
      line-height: 22px !important;
    }

    @include lt-xl {
      padding-left: 0;
      padding-right: 0;

      span.th {
        min-width: 50%;
        margin-right: 5px;
        color: var(--base-purple-70);
        font-size: 14px;
        line-height: 22px !important;
        font-weight: 500 !important;
      }

      span.td {
        font-size: 14px !important;
        line-height: 22px !important;
        font-weight: 500 !important;
      }

      &:first-child {
        span.td {
          font-size: 16px !important;
          line-height: 24px !important;
          font-weight: 600 !important;
        }
      }
    }
  }

  .card-table-header {
    background: var(--base-purple-5);
    border-top: 1px solid var(--base-purple-30);
    border-bottom: 1px solid var(--base-purple-30);
    margin: 0;

    @include lt-xl {
      border-top: none;
    }

    ec-card-table-header {
      min-height: 48px;
    }

    * {
      color: var(--base-purple-70);
      font-size: 14px;
      font-weight: 500;
    }

    .desc,
    .asc {
      transform: none !important;
    }
  }

  .card-table-body {
    margin: 0;
  }

  ec-card-table-header:first-of-type {
    padding-left: 16px !important;
  }
}
