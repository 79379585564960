@use 'mixins/all' as mixins;

@mixin bgPrimary {
  background: var(--base-ocher-40);
}

.btn {
  color: var(--base-blue-80);
  border-radius: 8px;
  border: none;
  box-shadow: none !important;

  &-primary,
  &-primary:focus,
  &-primary:visited,
  &-primary:hover {
    @include bgPrimary;
    @include mixins.btn-modifiers {
      background-color: var(--primary--hover);
      color: var(--base-blue-80);
      outline: none !important;
      box-shadow: none !important;
    }

    &:disabled {
      background: var(--medium-grey);
      color: var(--base-purple-30);
    }
  }

  &-white {
    color: var(--base-blue-80);
    background: var(--base-white);
    border: 1px solid var(--base-purple-70);
    @include mixins.btn-modifiers {
      background-color: var(--white-hover);
    }
  }

  &-danger {
    color: var(--base-red-60);
    background: var(--base-white);
    border: 1px solid var(--base-red-60);

    &:hover {
      color: var(--base-white);
      background: var(--base-red-60);
      border: 1px solid var(--base-red-60);
    }

    &:disabled {
      background-color: var(--base-purple-5);
      color: var(--base-purple-70);
      border: 1px solid var(--base-purple-20);
    }
  }

  &-outline {
    background-color: var(--base-white);
    border: 1px solid var(--base-purple-70);

    &:focus,
    &:visited,
    &:hover {
      background-color: var(--base-white) !important;
      border: 1px solid var(--base-purple-70) !important;
    }
  }
}
