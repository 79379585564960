ec-form-field .form-control {
  font-feature-settings: 'pnum' on, 'lnum' on;

  padding: 0.5rem;

  &:first-child {
    padding: 0.5rem 1rem;
  }
}

ec-otp-input {
  ec-input-error {
    display: none;
  }

  &.invalid {
    ec-input-error {
      display: block;
    }
  }
}

ec-datepicker {
  &.ng-invalid.ng-dirty {
    .input-group {
      border-color: var(--base-red-60);
    }
  }
}

label.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 4px;
  color: var(--base-blue-80);
}

.ec-form-field {
  .input-group {
    display: flex;
    align-items: center;
    border: 1px solid var(--base-purple-30);
    background-color: var(--base-white);
    border-radius: 8px;
    overflow: hidden;

    .ec-input-prefix {
      padding-left: 1rem;
    }

    .ec-input-suffix {
      margin-right: 16px;
    }

    &.is-disabled {
      background-color: var(--base-purple-5);

      input {
        background: unset;
      }
    }
  }

  .ec-input {
    border: none;
    color: var(--base-blue-80);
    -webkit-text-fill-color: var(--base-blue-80);
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    &::-ms-reveal {
      display: none;
    }

    &::placeholder {
      color: var(--base-purple-30);
      -webkit-text-fill-color: var(--base-purple-30);
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
  }

  .is-focused {
    border-color: var(--additional-purple-60);
  }

  .ec-input {
    &:focus {
      box-shadow: none;
    }
  }

  .has-danger {
    label {
      color: var(--base-red-60);
    }
  }
}

.ec-select {
  border: 1px solid var(--base-purple-30);
  border-radius: 8px;
  font-weight: 600;
  color: var(--base-blue-80);
  padding: 12px 16px;

  &:focus {
    box-shadow: none;
    border-color: var(--base-purple-60);
  }
}

.input-error {
  color: red;
  display: inline-block;
  //margin-left: 5px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}

ec-password-input {
  &.ng-invalid.ng-touched {
    div.input-group {
      border: 1px solid var(--base-red-60);
    }
  }
}
