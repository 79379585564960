@use 'shared/breakpoints' as breakpoints;

@mixin background($value) {
  background: $value;
}

ec-landing {
  @include breakpoints.lt-xl {
    .container {
      all: revert;
    }
  }
}

.bg {
  &-white {
    @include background(var(--base-white));
  }

  &-grey {
    @include background(var(--bg-grey));
  }
}

.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.z-index-10 {
  z-index: 10;
}

ec-accounts-wrapper {
  @include breakpoints.lt-sm {
    //zoom: 80%;
  }

  @include breakpoints.lt-xs {
    //zoom: 70%;
  }
}

.hide-xl {
  @include breakpoints.lt-xl {
    display: none;
  }
}

.show-xl {
  display: none;

  @include breakpoints.lt-xl {
    display: initial;
  }
}

.host {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &--hh {
    height: 250px;
  }

  &--fh {
    height: 100%;
  }
}
