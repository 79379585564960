@use 'shared/breakpoints';

.modal-backdrop {
  transition: opacity 0s;
  opacity: 0.9 !important;
  background-color: var(--base-blue-80);
}

.modal-open .modal {
  overflow: auto;

  @include breakpoints.lt-xl {
    align-items: baseline !important;
  }
}

.modal {
  display: flex !important;
  align-items: flex-start;

  .modal-dialog {
    width: 800px;
    max-width: calc(100% - 1rem);
    padding-bottom: 1.75rem;
  }

  &.modal-large-xl {
    .modal-dialog {
      max-width: 928px;
      width: 100%;
    }
  }

  &.modal-large {
    .modal-dialog {
      max-width: 800px;
      width: 100%;
    }
  }

  &.modal-medium {
    .modal-dialog {
      max-width: 602px;
    }
  }

  &.modal-small {
    .modal-dialog {
      max-width: 440px;
      margin: auto;
    }
  }

  .modal-title {
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 16px;
  }

  .modal-content {
    background: var(--base-white);
    border: 1px solid var(--base-purple-20);
    box-shadow: 0 6px 6px rgba(50, 49, 53, 0.04);
    border-radius: 16px;
  }

  .modal-header {
    background-color: var(--base-white);
    color: var(--base-blue-80);
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    //border-radius: 0;
    //border-top-left-radius: 8px;
    //border-top-right-radius: 8px;
    border: 0;
    padding: 0 0 1rem;
    justify-content: center;
  }

  .modal-body {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--base-blue-80);
    margin: 0 0 32px;
    padding: 0;

    @include breakpoints.lt-xl {
      margin: 0 0 24px;
    }

    .delete,
    .disable {
      color: var(--red);
    }

    .enable {
      color: green;
    }

    .status {
      font-weight: bold;
      color: var(--base-black);
    }

    ec-card-table-row,
    *[ec-card-table-row] {
      &:last-child {
        border-bottom: 1px solid var(--base-purple-20);
      }
    }
  }

  .modal-footer {
    color: var(--base-white);
    border-top: 0;
    justify-content: center;
    padding: 0;

    > * {
      margin: 0 0.5rem;
    }

    .btn {
      height: 40px;
      width: auto;

      &:disabled,
      &.disabled {
        cursor: default;
      }
    }

    .btn-white {
      color: var(--base-blue-80) !important;
    }
  }
}

@include breakpoints.lt-xl {
  .modal {
    &.modal-small {
      .modal-dialog {
        max-width: 100% !important;
        min-width: 100% !important;
        padding: 0 18px;
      }
    }

    .modal-title {
      font-size: 24px;
      line-height: 32px;
    }

    .modal-body {
      font-size: 14px;
      line-height: 24px;
    }

    .modal-footer {
      > * {
        font-size: 14px;
        margin: 0 0.5rem;

        &:first-child {
          margin: 0 0.5rem 0 0 !important;
        }

        &:last-child {
          margin: 0 0 0 0.5rem !important;
        }
      }

      .btn {
        height: 40px;
        padding: 8px 16px;
        flex: 1 1 auto;
      }
    }
  }
}
