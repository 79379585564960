@forward 'inputs.import';
@forward 'ng-custom-select';

/*

.ng-select {
  //color: var(--base-blue-80);
  //-webkit-text-fill-color: var(--base-blue-80);
  //font-weight: 600;
  font-size: 16px;

  &-container {
    padding: 0.65rem;
    height: auto !important;
    border-color: var(--base-purple-30) !important;
    border-radius: 8px !important;
  }
}

//.ng-select .ng-arrow-wrapper .ng-arrow {
//  display: none;
//}
//
//.ng-select span.ng-arrow-wrapper:before {
//  content: "\f0ab";
//  display: inline-block;
//  font: normal normal normal 14px/1 FontAwesome;
//  font-size: inherit;
//  text-rendering: auto;
//}
*/
