@forward 'mixins/icon-size-mixins.import';
@forward 'variables/icon-size-variables.import';
@use 'shared/breakpoints' as breakpoints;

button[ec-icon-wrapper] {
  background: none;
  outline: none;
  border: none;
  padding: 0;
}

.size {
  @for $i from 16 through 25 {
    &-#{$i} {
      svg {
        height: $i + px;
        width: $i + px;
      }
    }
  }
}

.icon-lock {
  @include breakpoints.lt-md {
    pointer-events: none;
  }
}

.icon {
  &.size {
    &-xs {
      svg {
        width: 16px;
        height: 16px;
        font-size: 16px;
      }
    }

    &-sm {
      svg {
        width: 18px;
        height: 18px;
        font-size: 18px;
      }
    }

    &-xsm {
      svg {
        width: 20px;
        height: 20px;
        font-size: 20px;
      }
      @include breakpoints.lt-xl {
        svg {
          width: 20px;
          height: 20px;
          font-size: 20px;
        }
      }
    }

    &-md {
      svg {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    &-lg {
      svg {
        width: 32px;
        height: 32px;
        font-size: 32px;
      }
    }

    &-xl {
      svg {
        width: 48px;
        height: 48px;
        font-size: 48px;
      }
    }
  }

  @include breakpoints.lt-xl {
    &.touch-size {
      &-xs {
        svg {
          width: 16px;
          height: 16px;
          font-size: 16px;
        }
      }

      &-sm {
        svg {
          width: 18px;
          height: 18px;
          font-size: 18px;
        }
      }

      &-xsm {
        svg {
          width: 20px;
          height: 20px;
          font-size: 20px;
        }
      }

      &-md {
        svg {
          width: 24px;
          height: 24px;
          font-size: 24px;
        }
      }

      &-lg {
        svg {
          width: 32px;
          height: 32px;
          font-size: 32px;
        }
      }

      &-xl {
        svg {
          width: 48px;
          height: 48px;
          font-size: 48px;
        }
      }

      &-xxl {
        svg {
          width: 200px;
          height: 200px;
          font-size: 200px;
        }
      }
    }
  }

  &.color {
    &-purple {
      * {
        fill: var(--base-purple-70);
      }
    }

    &-blue {
      * {
        fill: var(--base-blue-80);
      }
    }

    &-green {
      * {
        fill: var(--base-green-70);
      }
    }

    &-red {
      * {
        fill: var(--red);
      }
    }

    &-base-red {
      * {
        fill: var(--base-red-60);
      }
    }

    &-yellow {
      * {
        fill: var(--base-ocher-40);
      }
    }

    &-gray {
      * {
        fill: var(--base-purple-70);
      }
    }

    &-light {
      * {
        fill: var(--base-purple-30);
      }
    }

    &-white {
      * {
        fill: var(--base-white);
      }
    }
  }
}
