@forward 'ngb-modal';
@use 'shared/breakpoints' as breakpoints;

.project-map {
  .modal-dialog {
    width: auto !important;

    @include breakpoints.lt-xl {
      width: 100% !important;
    }
  }
}
