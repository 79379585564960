@use 'shared/breakpoints' as breakpoints;

section.grouped {
  ec-section {
    section {
      padding: 16px 0 6px;
      border-bottom: none !important;

      @include breakpoints.lt-xl {
        padding: 12px 0;
      }

      > header {
        min-height: 64px;
      }

      main {
        padding-top: 6px !important;

        header {
          margin-bottom: 1rem !important;
          padding-bottom: 1rem !important;
          border-bottom: 1px solid var(--medium-grey) !important;

          @include breakpoints.lt-xl {
            flex-direction: column !important;
            align-items: flex-start !important;
          }
        }
      }
    }

    &.wait-list {
      section {
        background-color: var(--base-purple-5);
      }
    }

    *[ec-card] {
      &:not(:first-child) {
        margin-top: 1rem;
      }
    }
  }
}
