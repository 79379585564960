ngx-charts-line-chart {
  g.tick {
    fill: var(--base-purple-70);
  }
}

ngx-charts-pie-chart {
  path.arc {
    &.active {
      fill: var(--base-ocher-40);
      transform: scale(1.02);
    }
  }
}
