.badge-container {
  position: relative;
  display: inline-block;
}

.badge {
  position: absolute !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-color);
  color: var(--base-blue-80);
  font-weight: 500;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 2px 6px -1px rgb(0 0 0 / 50%);
}

.badge.primary {
  --bg-color: var(--base-ocher-40);
}

.badge.success {
  --bg-color: var(--base-green-70);
  color: var(--base-white);
}

.badge.secondary {
  --bg-color: var(--secondary);
}

.badge.top {
  top: -7px;
}

.badge.bottom {
  bottom: -7px;
}

.badge.left {
  left: -7px;
}

.badge.right {
  right: -7px;
}

.badge.xs {
  width: 12px;
  height: 12px;
  font-size: 0;
  top: 0;
  right: 0;
}

.badge.small {
  width: 18px;
  height: 18px;
  font-size: 12px;
}

.badge.medium {
  width: 22px;
  height: 22px;
  font-size: 13px;
}

.badge.large {
  width: 28px;
  height: 28px;
  font-size: 14px;
}
