@use 'shared/breakpoints' as breakpoints;

body {
  min-height: 100vh;
  min-width: 320px;

  font-family: 'Montserrat', sans-serif;
  font-feature-settings: 'lnum';
  font-size: 14px;
  font-weight: 500;
  color: var(--base-purple-70);

  overflow-x: hidden;
  max-width: 100%;
}

.pointer {
  cursor: pointer;
}

.font-w-500 {
  font-weight: 500;
}

.font-w-600 {
  font-weight: 600;
}

.font-w-700 {
  font-weight: 700;
}

.text {
  &-link {
    color: var(--dark-blue) !important;
    text-decoration: underline;

    &:hover {
      color: var(--dark-blue);
    }
  }

  &-break-all {
    word-break: break-all;
    word-wrap: break-word;
  }

  &-cut {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;

    &.max-1 {
      -webkit-line-clamp: 1;
    }
  }

  &-right {
    text-align: right;
  }

  //COLORS
  &-primary {
    color: var(--base-blue-80);
  }

  &-secondary {
    color: var(--base-purple-70);
  }

  //FAMILY
  &-montserrat {
    font-family: Montserrat, sans-serif;
  }

  //COMBINATION
  &-description {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;

    @include breakpoints.lt-xl {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &-basic {
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    color: var(--base-blue-80);

    @include breakpoints.lt-xl {
      font-size: 14px;
    }
  }

  &-basic-montserrat {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    color: var(--base-purple-70);

    @include breakpoints.lt-xl {
      font-size: 14px;
    }
  }
}

.ec-text-shortener {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
