.text {
  &-link {
    color: var(--dark-blue) !important;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: var(--dark-blue);
    }

    &.disabled {
      text-decoration: none;
      pointer-events: none;
    }
  }
}

.pointer {
  &-none {
    pointer-events: none !important;
    cursor: default !important;
  }
}

.dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--base-blue-80);
}

.rotate {
  &-180 {
    transform: rotate(180deg);
  }
}
