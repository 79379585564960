@use 'shared/breakpoints' as breakpoints;

.modal {
  .modal-dialog {
    top: 50px;
  }

  &-wrapper {
    padding: 40px;

    @include breakpoints.lt-xl {
      padding: 32px 24px;
    }

    .modal {
      &-header {
        justify-content: space-between !important;
        align-items: center !important;
        padding-bottom: 30px !important;

        @include breakpoints.lt-xl {
          padding-bottom: 15px !important;
        }
      }

      &-body {
        padding: 15px;
        text-align: left !important;

        @include breakpoints.lt-xl {
          padding-bottom: 7px;
        }
      }

      &-footer {
        justify-content: flex-end !important;

        button {
          margin-left: 12px;
          margin-right: 0;

          &:first-child {
            margin-left: 0 !important;
          }
        }
      }
    }
  }
}
