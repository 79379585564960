@use 'shared/breakpoints' as breakpoints;

.badge {
  &-circle {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    line-height: 24px;
    background: var(--base-blue-80);
    color: var(--base-white);
    border-radius: 50%;
    vertical-align: middle;
    width: 40px;
    height: 40px;

    @include breakpoints.lt-xl {
      width: 36px;
      height: 36px;
    }
  }
}

.chip {
  &-status {
    width: fit-content;
    padding: 5px 12px;
    border-radius: 50px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    display: inline-block;
    white-space: nowrap;

    background-color: var(--base-grey-10);
    color: var(--base-purple-70) !important;

    @include breakpoints.lt-xl {
      padding: 1px 8px;
    }
  }
}

$statusBgColors: (
  'Completed': var(--additional-blue-10),
  'Active': var(--additional-green-10),
  'Won': var(--additional-green-10),
  'InPay': var(--additional-green-10),
  'Funded': var(--additional-green-10),
  'Purchased': var(--additional-green-10),
  'Pending': var(--additional-ocher-10),
  'Open': var(--additional-ocher-10),
  'DUC': var(--additional-ocher-10),
  'Processing': var(--additional-ocher-10),
  'Cancelled': var(--additional-red-10),
  'Unknown': var(--base-purple-30),
  'Waitlist': var(--additional-blue-10),
  'Winning': var(--additional-blue-10),
  'HighestBid': var(--additional-blue-10),
  'OnMarket': var(--additional-ocher-10),
  'Connected': var(--additional-green-10),
  'On Hold': var(--base-grey-10),
  'Pending-transfer': var(--additional-blue-10),
  'Manual-Verification': var(--additional-ocher-10),
  'Verification-Failed': var(--additional-red-10),
  'Verification-Expired': var(--additional-red-10),
  'Pending-Verification': var(--additional-ocher-10),
  'Verify-Account': var(--additional-blue-10),
  'Pending_Deletion': var(--additional-red-10),
  'Not-Connected': var(--base-white),
  'Locked': var(--base-white),
);

$statusTextColors: (
  'Completed': var(--base-blue-80),
  'Active': var(--additional-green-80),
  'Won': var(--additional-green-80),
  'InPay': var(--additional-green-80),
  'Funded': var(--additional-green-80),
  'Purchased': var(--additional-green-80),
  'Pending': var(--additional-ocher-80),
  'Open': var(--additional-ocher-80),
  'DUC': var(--additional-ocher-80),
  'Processing': var(--additional-ocher-80),
  'Cancelled': var(--additional-red-80),
  'Unknown': var(--base-white),
  'Waitlist': var(--base-blue-80),
  'Winning': var(--base-blue-80),
  'HighestBid': var(--base-blue-80),
  'OnMarket': var(--additional-ocher-80),
  'Connected': var(--additional-green-80),
  'On Hold': var(--base-purple-70),
  'Pending-transfer': var(var(--base-blue-80)),
  'Manual-Verification': var(--additional-ocher-80),
  'Verification-Failed': var(--additional-red-80),
  'Verification-Expired': var(--additional-red-80),
  'Pending-Verification': var(--additional-ocher-80),
  'Verify-Account': var(var(--base-blue-80)),
  'Pending_Deletion': var(--additional-red-80),
  'Not-Connected': var(--base-purple-70),
  'Locked': var(--base-purple-70),
);

$statusBorderColors: (
  'Not-Connected': var(--base-grey-10),
  'Locked': var(--base-grey-10)
);

@each $color, $i in $statusBgColors {
  .bg,
  .chip-status {
    &-#{$color},
    &.#{$color} {
      background-color: $i !important;
    }
    &-updates {
      &.#{$color} {
        background-color: transparent !important;
        padding: 0;
      }
      background-color: transparent !important;
      padding: 0;
    }
  }
}

@each $color, $i in $statusTextColors {
  .text,
  .chip-status {
    &-#{$color},
    &.#{$color} {
      color: $i !important;
    }
  }
}

@each $color, $i in $statusBorderColors {
  .bg,
  .chip-status {
    &-#{$color},
    &.#{$color} {
      border: 1px solid $i !important;
    }
  }
}
