@mixin loadVariables() {
  --primary--hover: #fecf65;
  //--primary--light: rgba(249, 185, 42, 0.15);
  --white-hover: #f5f6f8;
  --light-blue: #f2f9fd;
  --bg-grey: #f5f6f8;
  --grey-bg: #ebebec;
  --medium-grey: #d7d6da;
  --dark-green: #34555f;
  --blue-green: #587d8f;
  --green: var(--base-green-70);
  --light-green: #deffeb;
  --dark-red: #363543;
  --red: #f92a2a;
  --light-red: #fde8e8;
  --light-black: #333333;
  --light-yellow: #fff7e3;
  --light-yellow-10: #fffbf0;
  --light-grey: #eceae8;
  --yellow: #fab92a;
  --grey-placeholder: #4f505e;
  --grey-grey-5: #F7F7F8;

  // new design system name
  --base-brown-50: #837467;
  --base-ocher-40: #f9b92a;
  --base-blue-5: var(--light-blue);
  --base-blue-80: #0a2b3c;
  --base-purple-70: #544e64;
  --base-purple-30: #a8a3b7;
  --base-purple-20: #c9c6d2;
  --base-purple-10: #e4e2e9;
  --base-purple-5: #f6f6f8;
  --base-grey-20: #cbcace;
  --base-grey-10: #e5e4e7;
  --base-grey-102: #fffbf0;
  --base-green-70: #00993d;
  --base-red-60: #c60606;
  --base-black: #000000;
  --base-white: #ffffff;

  --additional-green-80: #006629;
  --additional-green-10: #ccffe0;
  --additional-red-80: #630303;
  --additional-red-50: #f80707;
  --additional-red-10: #fecdcd;
  --additional-red-5: #fef0f0;
  --additional-ocher-80: #634503;
  --additional-ocher-50: #f8ad07;
  --additional-ocher-30: #ffd466;
  --additional-ocher-10: #fff1cc;
  --additional-ocher-5: #fffbf0;
  --additional-brown-80: #343538;
  --additional-brown-5: #f8f7f6;
  --additional-brown-10: #e9e5e2;
  --additional-blue-90: #071f2c;
  --additional-blue-70: #165e83;
  --additional-blue-60: #0000ef;
  --additional-blue-10: #d3ebf8;
  --additional-purple-60: #605973;
}
