@use 'shared/breakpoints' as breakpoints;

.dropdown-menu {
  padding: 0;
  background-color: var(--base-white);
  right: 0;
  left: auto;
  border: 1px solid var(--medium-grey);
  box-shadow: 0 6px 6px rgba(61, 61, 61, 0.04);
  border-radius: 8px;
  overflow: hidden;
  min-width: auto;

  .dropdown-item {
    height: 40px;
    font-weight: 500;
    line-height: 18px;
    font-size: 14px;
    padding: 8px 10px 8px 1rem;
    color: var(--base-blue-80);

    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--medium-grey);
    }

    &:hover {
      background-color: var(--additional-ocher-5);
    }

    &.selected {
      background-color: var(--light-blue);
    }

    &.wrapper {
      position: relative;

      .title {
        padding-left: 22px;

        &.delete {
          color: var(--base-red-60);
        }
      }

      .input-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 22px;
      }
    }
  }
}

ngb-popover-window {
  &.popover {
    font-family: 'Montserrat', sans-serif;
  }

  &.pop-600 {
    max-width: 600px;
    width: 100%;
  }

  &.pop-with-scroll {
    .popover-body {
      max-height: 350px;
      overflow: auto;
    }
  }

  &.pop-hint {
    min-width: 120px;
    max-width: 360px;
    width: max-content;

    .popover-body {
      color: var(--base-blue-80);
      padding: 24px !important;

      font-size: 14px;
      font-weight: 500;
    }
  }

  &.pop-table {
    max-width: 850px;
    min-width: 850px;

    @include breakpoints.lt-xl {
      min-width: 650px;
    }

    @include breakpoints.lt-md {
      min-width: 500px;
    }

    @include breakpoints.lt-sm {
      min-width: 300px;
    }
  }

  &.pop-on-hover {
    max-width: 260px !important;
    min-width: 260px !important;
    .popover-body {
      padding: 1rem !important;
    }
  }
}

ec-dropdown {
  .dropdown {
    &-toggle::after {
      display: none !important;
    }
  }

  &-item:not(:last-child) {
    button.dropdown-item {
      border-bottom: 1px solid var(--medium-grey);
    }
  }

  &-item {
    button.dropdown-item {
      border-radius: 0 !important;
      display: flex;
      align-items: center;

      svg {
        width: 16px;
        height: 22px;
        color: var(--base-purple-70);
        fill: var(--base-purple-70);

        path {
          fill: var(--base-purple-70);
        }
      }
    }

    span.dropdown-icon {
      margin-right: 8px;
    }

    span.dropdown-label {
      font-weight: 500;
      color: var(--base-blue-80);
    }

    .selected span.dropdown-label {
      font-weight: 600;
    }
  }
}
