@use 'shared/breakpoints' as breakpoints;

.toast-container {
  top: 120px !important;
  right: 0 !important;
  margin: 0 40px;

  .ngx-toastr {
    box-shadow: unset !important;
    border-radius: 10px !important;
    min-width: 341px;
    max-width: 360px;
    width: unset !important;
    min-height: 88px;

    color: var(--base-blue-80) !important;
    font-family: 'Montserrat', sans-serif;

    background-position: 17.67px 48.67px !important;
    background-size: 16.67px !important;

    .toast-close-button {
      background-color: var(--base-blue-80);
      -webkit-mask-image: url('/assets/icons/close-big.svg');
      mask-image: url('/assets/icons/close-big.svg');

      margin-top: 8px;
      width: 24px;
      height: 24px;

      span {
        visibility: hidden;
      }
    }

    .toast-title {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      margin-left: -32px;
      margin-top: 7px;
    }

    .toast-message {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-top: 6px;
      margin-left: -4px;
      max-width: 252px;
      a {
        color: var(--base-blue-80);
        font-weight: 700;
        text-decoration: underline;
      }
    }

    &.toast-success {
      background-color: var(--additional-green-10);
      border: 1px solid var(--base-green-70);
      background-image: url('/assets/icons/checked-circle-green.svg');
    }

    &.toast-error {
      background-color: var(--additional-red-10);
      border: 1px solid var(--additional-red-50);
      background-image: url('/assets/icons/red-cross.svg');
    }

    &.toast-warning {
      background-color: var(--additional-ocher-10);
      border: 1px solid var(--additional-ocher-50);
    }
  }

  @include breakpoints.lt-sm {
    top: 50px !important;
    margin: 10px;

    .ngx-toastr {
      min-width: 240px;
    }
  }
}
