/* Media query breakpoints */
$width-xs: 360px;
$width-sm: 600px;
$width-md: 768px;
$width-lg: 992px;
$width-xl: 1200px;
$width-xxl: 1400px;
$width-xxxl: 1600px;

// UI
$checkbox-border: var(--base-purple-30);

// COLORS
$image-overlay-dark: #272727;
