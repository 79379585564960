// Project
@use 'reset/reset.import';
@use 'typography/all' as typography;
@use 'shared/breakpoints' as breakpoints;
@use 'buttons/all' as buttons;
@use 'statuses/status' as status;
@use 'dividers/all' as dividers;
@use 'forms/all' as forms;
@use 'icons/all' as icon;
@use 'background/all' as backgrounds;
@use 'variables/fixed-width' as fixed-width;
@use 'variables/palette.import' as palette;
@use 'inner-html/all' as innerStyles;
@use 'notifications/all' as notifications;
@use 'modules/modals/all' as modals;
@use 'charts/charts' as charts;
@use '../app/modules/widgets/table/styles/table' as table;
@use '../app/modules/widgets/badge/styles/badge' as badge;
@use '../app/modules/widgets/dropdown/styles/dropdown' as dropdown;

@import '~@ng-select/ng-select/themes/default.theme.css';
@import '../assets/fonts/all';
@import 'shared/breakpoints';
@import 'shared/spacing';

html {
  @include palette.loadVariables;
}

body {
  font-family: 'Montserrat', sans-serif;

  &.modal-open {
    overflow: hidden;
  }
}

.popover {
  max-width: 400px;
  width: 400px;
  border-radius: 8px;
  border: 1px solid var(--base-purple-20);

  &-body {
    padding: 1.5rem;
  }

  @include breakpoints.lt-sm {
    max-width: 280px;
  }
}

.point {
  width: 4px;
  height: 4px;
  border-radius: 100%;

  display: inline-block;
  background-color: var(--base-purple-70);
  position: relative;
  bottom: 2px;
}

ngb-datepicker {
  .text-muted {
    color: var(--bs-secondary) !important;
  }
}

ngb-popover-window {
  //font-family: Raleway, sans-serif !important;

  &.popover-table {
    width: 100%;
    max-width: 800px;
  }

  &.pop-info {
    width: 100%;
    max-width: 500px;

    .popover {
      &-header {
        color: var(--base-blue-80);
        font-size: 20px;
        padding: 24px 24px 0;
        font-weight: 600;
        background-color: var(--base-white);
        border-bottom: none;
        border-radius: 8px;
      }

      &-body {
        color: var(--base-blue-80);
        padding: 16px 24px 24px;
        font-family: Montserrat, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  &.lease-pop {
    width: auto;
    max-width: none;

    .popover-body {
      color: var(--base-blue-80);
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
    }
  }
}

.dropdown {
  &-menu {
    padding: 0;
    border-radius: 8px;
  }

  &-item {
    padding: 0.5rem 1rem;

    &:active {
      background-color: transparent;
    }
  }

  &-label {
    @include breakpoints.lt-xl {
      font-size: 14px;
    }
  }
}

ec-dropdown-item.disabled {
  pointer-events: none;
}

.show-on-touch,
.show-on-touch {
  display: none;

  @include lt-xl {
    display: block !important;
  }
}

.hide-on-touch,
.hide-on-touch {
  display: block !important;

  @include lt-xl {
    display: none !important;
  }
}

.snippet-hidden {
  display: none !important;
}

#snippet {
  z-index: -1;
}

.menu-snippet {
  .snippet {
    position: relative !important;
  }
}

.snippet {
  z-index: 9 !important;
  position: absolute;
  top: -70px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  left: 50%;
  transform: translate(-50%);
  padding: 16px 0;

  @include breakpoints.lt-xl {
    position: fixed;
    top: inherit;
    bottom: 0;
    left: 0;
    transform: none;
    justify-content: flex-start;
    padding: 6px 0;
  }

  @include breakpoints.lt-sm {
    flex-direction: column;
  }

  .snippet-part {
    width: 250px;
    border: solid 1px #e4e2e9;
    border-radius: 7px;
    padding: 8px;
    margin-left: 16px;
    background-color: var(--bs-white);
    margin-top: 5px;
    pointer-events: none;

    tbody {
      font-size: 14px;

      a {
        color: var(--additional-blue-60);
      }
    }

    &:last-child {
      width: 266px;
    }

    @include breakpoints.lt-xl {
      margin-left: 12px;
      padding: 2px;
      width: fit-content;

      &:last-child {
        width: fit-content;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.w-max {
  width: max-content;
}
