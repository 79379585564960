@use 'shared/breakpoints' as breakpoints;

.btn {
  //height: 44px;
  min-height: 40px;
  font-weight: 600;
  padding: 8px 16px;
  font-size: 14px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @include breakpoints.lt-xl {
    padding: 8px 12px;
  }

  &-std {
    border-radius: 8px;
    //min-width: 100px;
    font-weight: 600;
    line-height: 24px;
  }

  &-sm {
    font-size: 14px !important;
    padding: 6px 12px !important;
  }

  &-lg {
    font-size: 16px !important;
    padding: 12px 24px !important;
  }

  *[ec-icon-wrapper] {
    margin-right: 8px;
  }
}

.circle {
  display: inline-block;
  border-radius: 50%;

  height: 16px;
  width: 16px;

  background-color: var(--base-green-70);

  &.red {
    background-color: var(--base-green-70);
  }
}

a[ec-link] {
  display: inline-flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  &.disabled {
    *[ec-icon-wrapper] path {
      fill: var(--base-purple-30) !important;
    }
  }

  *[ec-icon-wrapper] {
    margin-right: 8px;
  }
}

.load-more-btn {
  @include breakpoints.lt-xl {
    width: 100%;
  }
}
