@use 'shared/breakpoints' as breakpoints;

.content {
  @include breakpoints.lt-sm {
    padding: 5px;
  }

  &.content-inner {
    * {
      padding: revert;
      text-decoration: revert;
    }

    a {
      color: var(--base-blue-80);
      text-decoration: underline;
      font-weight: 600;
    }

    div {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: var(--base-blue-80);
    }

    blockquote {
      width: 100%;

      &:before {
        display: block;
        content: url('~assets/icons/quote.svg');
      }
    }

    img {
      width: auto;
      max-width: 100%;

      @include breakpoints.lt-xl {
        width: 100%;
      }
    }
  }
}
