@use 'shared/breakpoints' as breakpoints;

h1,
h2,
h3 {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: var(--base-blue-80);
}

h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: var(--base-blue-80);

  &.subheader {
    color: var(--base-purple-70);
    font-weight: 500 !important;
  }
}

h1 {
  font-size: 40px;
  line-height: 52px;

  @include breakpoints.lt-xl {
    font-size: 28px;
    line-height: 36px;
  }

  &.subtitle {
    font-size: 32px;
    line-height: 42px;

    @include breakpoints.lt-xl {
      font-size: 24px;
      line-height: 32px;
    }
  }
}

h1.lg {
  font-size: 48px;
  line-height: 60px;

  @include breakpoints.lt-xl {
    font-size: 32px;
    line-height: 40px;
  }
}

h2 {
  font-size: 32px;
  line-height: 42px;

  @include breakpoints.lt-xl {
    &.sm {
      font-size: 20px;
      line-height: 28px;
    }

    font-size: 24px;
    line-height: 32px;
  }
}

h3 {
  font-size: 28px;
  line-height: 38px;

  @include breakpoints.lt-xl {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 24px;
  line-height: 34px;

  @include breakpoints.lt-xl {
    font-size: 18px;
    line-height: 28px;
  }
}

h5 {
  font-size: 18px;
  line-height: 26px;

  @include breakpoints.lt-xl {
    font-size: 16px;
    line-height: 24px;
  }
}

h6 {
  font-size: 16px;
  line-height: 24px;

  @include breakpoints.lt-xl {
    font-size: 16px;
    line-height: 24px;
  }
}

.title {
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
  color: var(--base-blue-80);

  @include breakpoints.lt-xl {
    font-size: 28px;
    line-height: 36px;
  }
}

.subtitle {
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;

  @include breakpoints.lt-sm {
    font-size: 22px;
    line-height: 32px;
  }
}

.paragraph {
  font-size: 16px;
  line-height: 24px;
  color: var(--base-blue-80);
}
